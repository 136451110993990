import { useThoughtLeadershipSections } from "types/thoughtLeadershipSection";
import styles from "./styles.module.scss";
import { classNames } from "utilities/classNames";
import Slider from "react-slick";
import { TextLink } from "components/common/textLink";
import { useRef, useState } from "react";
import Transition from "components/controls/transition";
import { ReactComponent as IconChevronLeft } from "images/icons/icon-chevron-left.svg";
import { ReactComponent as IconChevronRight } from "images/icons/icon-chevron-right.svg";

export function HomeEditorialsSection() {
  const thoughtLeadershipSections = useThoughtLeadershipSections();
  const homePageQuotesSlug = "home-page-quotes";
  const quoteSection = thoughtLeadershipSections.find((x) => x.fields.slug === homePageQuotesSlug);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  let sliderRef = useRef<Slider | null>(null);

  const next = () => {
    sliderRef?.current?.slickNext();
  };

  const previous = () => {
    sliderRef?.current?.slickPrev();
  };

  const handleSlideChange = (_: number, next: number) => {
    setActiveSlideIndex(next);
  };

  if (!quoteSection) return null;

  const { backgroundColor, slides = [] } = quoteSection.fields;
  const activeSlide = slides[activeSlideIndex];
  const activeImgUrl = activeSlide?.fields?.img?.fields?.file?.url;
  const activeImgDescription = activeSlide?.fields?.img?.fields?.description;

  return (
    <section style={{ backgroundColor }} className={styles.section}>
      <Slider
        ref={(slider) => {
          sliderRef.current = slider;
        }}
        // tyring to use the "swipe" effect on mobile...doesn't seem to take...
        // responsive={[
        //   {
        //     breakpoint: 1024,
        //     settings: {
        //       swipe: true,
        //       draggable: true,
        //     },
        //   },
        // ]}
        arrows={false}
        appendDots={(dots) => {
          return (
            <ul className={styles.dots}>
              <button className={styles.arrow} onClick={previous}>
                <IconChevronLeft width={20} />
              </button>
              {dots}
              <button className={styles.arrow} onClick={next}>
                <IconChevronRight width={20} />
              </button>
            </ul>
          );
        }}
        customPaging={(i) => <div className={styles.dot} data-active={activeSlideIndex === i} />}
        autoplay
        autoplaySpeed={10000}
        beforeChange={handleSlideChange}
        className={styles.slider}
        dots
        dotsClass={classNames("slick-dots", styles.dots)}
        slidesToShow={1}
        fade
      >
        {slides?.map((x) => {
          const { header, author, authorTitle, linkUrl = "", isLinkExternal } = x.fields;

          return (
            // grid's positioning is behaving differently when slide's first element is article vs. div...
            <div key={x.sys.id}>
              <article className={styles.editorial}>
                <h3 className={styles.header}>{header}</h3>
                <p className={styles.author}>
                  {author}, {authorTitle}
                </p>
                <TextLink className={styles.link} useArrow={false} external={isLinkExternal} to={linkUrl}>
                  Read More
                </TextLink>
              </article>
            </div>
          );
        })}
      </Slider>
      {/* rendering current image with its own grid positioning so it fits better in relation to border cards */}
      <div className={styles.photo_position}>
        <Transition animation="fadeIn" duration={1000} key={activeImgUrl}>
          <img src={activeImgUrl} alt={activeImgDescription} className={styles.photo} />
        </Transition>
      </div>
      <div className={classNames(styles.border_card, styles.border_card_center)}></div>
      <div className={classNames(styles.border_card, styles.border_card_left)}></div>
      <div className={classNames(styles.border_card, styles.border_card_right)}></div>
    </section>
  );
}
